import Vue from "vue";
import VueGtag from "vue-gtag";
import VueGtm from "@gtm-support/vue2-gtm";

export default {
  data() {
    return {
      analytics: {},
      ogp: {},
      name: "",
      statusCode: "",
      global: [],
      extra: [],
      footer: [],
      count: {},
      organization: {},
      information: {},
      sns: {},
      rss: {},
      banner: {
        items: {},
        setting: {},
      },
      categories: {},
      openType: "",
      translation: "",
      preview: false,
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path || to.query !== from.query) {
        if (
          this.analytics.access_type == "ga" &&
          this.open_type !== 0 &&
          !this.preview
        ) {
          this.tracking();
        }
      }
    },
  },

  mounted() {
    const initialsRepository = this.$repository.get("initials");
    const initials = initialsRepository.index();
    const self = this;
    initials
      .then((result) => {
        /** ベースとなるlanguageの設定 */
        self.translation = result.data.response.translation;
        if (self.translation.is_use == 1) {
          var query = self.translation.active_language.filter(
            (e) => e.code == self.$route.query.hl
          );
          if (
            self.$route.query.hl &&
            self.translation.is_use == 1 &&
            query.length > 0
          ) {
            self.$store.dispatch("setLanguage", { lang: self.$route.query.hl });
          } else if (
            self.$cookies.get("iv-language") &&
            self.translation.is_use == 1
          ) {
            self.$store.dispatch("setLanguage", {
              lang: self.$cookies.get("iv-language"),
            });
          } else {
            self.$store.dispatch("setLanguage", {
              lang: self.translation.default_lang,
            });
          }
        } else {
          self.$store.dispatch("setLanguage", {
            lang: self.translation.default_lang,
          });
        }
        self.statusCode = result.status;

        self.setSettings();
        self.setNewsCategories();
        self.setThemeLang();
      })
      .catch((error) => {
        console.error(error);

        if (error.response === undefined) {
          self.statusCode = 503;
        } else {
          self.statusCode = error.response.status;
        }
      })
      .finally(() => {
        self.setPublicLang();
      });
  },

  methods: {
    setSettings() {
      const settingsRepository = this.$repository.get("settings");
      const settings = settingsRepository.index();
      const self = this;
      settings
        .then((result) => {
          const res = result.data.response;
          self.analytics = res.analytics;
          self.ogp = res.sns;
          self.name = res.organization.name;
          if (self.$route.query.mode == "preview") {
            self.openType = 1;
            self.preview = true;
          } else {
            self.openType = res.open_type;
            self.preview = false;
          }
          self.global = res.navigation.global;
          self.extra = res.navigation.extra;
          self.footer = res.navigation.footer;
          self.bottom = res.navigation.bottom;
          self.count = res.navigation.count;
          self.organization = res.organization;
          self.information = res.information;
          self.sns = res.sns;
          self.rss = res.blog;
          self.banner = res.banner;

          /** language用のcookie設定 */
          if (
            self.$route.query.hl &&
            !self.$cookies.get("iv-language") &&
            self.translation.is_use == 1
          ) {
            var lang = self.$route.query.hl;
            self.$cookies.set("iv-language", lang, "30min");
          }

          if (self.openType === 1 && !self.preview) {
            /** レポート解析の設定 */
            if (self.analytics.is_use == 1) {
              if (self.analytics.access_type == "ga") {
                // Google Analytics
                Vue.use(VueGtag, {
                  config: {
                    id: self.analytics.tracking_id,
                    params: {
                      send_page_view: false,
                    },
                  },
                });
                self.tracking();
              } else if (self.analytics.access_type == "gtm") {
                // Google Tag Manager
                Vue.use(VueGtm, {
                  id: self.analytics.container_id,
                });
              }
            }

            /** One Signalの設定 */
            if (res.onesignal.app_id) {
              var webpush = res.onesignal;
              var OneSignal = window.OneSignal || [];
              OneSignal.push(() => {
                OneSignal.init({
                  appId: webpush.app_id,
                  notifyButton: self.$store.state.publicLang.onesignal,
                });
              });
            }
          }

          /** meta、OGPの設定 */
          document
            .querySelector("html")
            .setAttribute("lang", self.$store.state.language);
          document
            .querySelector('link[rel="canonical"]')
            .setAttribute("href", location.href);
          document
            .querySelector('link[rel="icon"]')
            .setAttribute("href", self.information.favicon_image);
          document
            .querySelector('meta[property="og:url"]')
            .setAttribute("content", location.href);
          if (self.$route.path == "/") {
            document
              .querySelector('meta[property="og:type"]')
              .setAttribute("content", "website");
          } else {
            document
              .querySelector('meta[property="og:type"]')
              .setAttribute("content", "article");
          }
          document
            .querySelector('meta[property="og:site_name"]')
            .setAttribute("content", self.name);
          if (self.ogp.ogp_image) {
            self.$store.dispatch("setOgpImage", { img: self.ogp.ogp_image });
            document
              .querySelector('meta[property="og:image"]')
              .setAttribute("content", self.ogp.ogp_image);
          }
          if (self.ogp.twitter_id) {
            document
              .querySelector('meta[name="twitter:site"]')
              .setAttribute("content", "@" + self.ogp.twitter_id);
          }

          // カラースキームの設定
          document.body.classList = "theme-c__" + self.information.color_scheme;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setNewsCategories() {
      const newsRepository = this.$repository.get("news");
      const categories = newsRepository.categories("blog");
      const self = this;
      categories.then((result) => {
        self.categories = result.data.response.categories;
      });
    },

    setPublicLang() {
      const languageRepository = this.$repository.get("language");
      const publicLang = languageRepository.public();
      const self = this;
      publicLang
        .then((result) => {
          const res = result.data;
          self.$store.dispatch("setPublicLang", { obj: res });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setThemeLang() {
      const languageRepository = this.$repository.get("language");
      const themeLang = languageRepository.theme();
      const self = this;
      themeLang
        .then((result) => {
          const res = result.data;
          self.$store.dispatch("setThemeLang", { obj: res });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    tracking() {
      this.$gtag.pageview(this.$route);
    },
  },
};
